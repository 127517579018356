import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroBanner = ({
  img,
  img2,
  img3,
  img4,
  img5,
  title,
  desc,
  btnTitle = "",
  viewType = "",
}) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false, 
    pauseOnFocus: false,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       arrows: false,
    //     },
    //   },
    // ],
  };

  return (
    <>
      {viewType === "home" ? (
        <Slider {...settings} arrows={false}>
          {/* <div> */}
          <img src={img} alt="Image1" className="full-width-image" />
          <img src={img2} alt="Image2" className="full-width-image" />
          <img src={img3} alt="Image3" className="full-width-image" />
          <img src={img4} alt="Image4" className="full-width-image" />
          {/* </div> */}
        </Slider>
      ) : (
        <div className="relative overflow-hidden">
          <div
            id="imageCarousel"
            className="flex transition-transform duration-1000 ease-in-out"
          >
            <img
              src={img}
              alt="Background"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default HeroBanner;
