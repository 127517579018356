import React from "react";
import HeroBanner from "../Shared/HeroBanner";
import ContentRightLeft from "../Shared/ContentRightLeft";
import Main_banner_modeling from "../../Images/WebsiteImages/3D Animation & Modeling/Modeling.webp";
import game_development_simple from "../../Images/WebsiteImages/3D Animation & Modeling/1_game-development_simple.webp";
import modlind_3d from "../../Images/WebsiteImages/3D Animation & Modeling/3D-Modeling.webp";

const Modeling = () => {
  return (
    <>
      <HeroBanner
        img={Main_banner_modeling}
     
      />
       <ContentRightLeft
        // title="3D Modeling & Animation"
        imgSrc={modlind_3d}
        desc="3D modeling is the process of creating digital representations of objects or environments in three dimensions. It involves using specialized software to sculpt, manipulate, and texture virtual objects, bringing them to life. 3D modeling finds applications in various industries, including gaming, animation, architecture, and product design. It allows artists and designers to visualize concepts, create realistic characters, design architectural spaces, and prototype products. By employing polygons, curves, and textures, 3D modeling brings imagination into the digital realm, enabling the creation of visually compelling and immersive experiences."
      />
   
      <ContentRightLeft
        imgPosition="right"
        imgSrc={game_development_simple}
        // title="AR Filters"
        desc="Animation is the art of bringing static objects or characters to life through the illusion of motion. It involves creating a sequence of images or frames and rapidly displaying them in succession. Animators use various techniques, including traditional hand-drawn animation, computer-generated imagery (CGI), and stop motion, to create captivating and dynamic visuals. Animation finds applications in films, television shows, video games, advertising, and more. It allows storytellers to convey emotions, tell compelling narratives, and create immersive worlds that captivate audiences of all ages. Through skillful manipulation of movement, timing, and expression, animation has the power to evoke emotions, entertain, and inspire."
      />
    </>
  );
};

export default Modeling;
