import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// import Header1 from "../Header1";

const Layout = ({ children }) => {
  return (
    <>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
