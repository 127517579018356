import React from "react";
import HeroBanner from "../Shared/HeroBanner";
import ContentRightLeft from "../Shared/ContentRightLeft";
import VR_page_main_banner from "../../Images/WebsiteImages/Virtual_Reality/VR.webp";
import simple_vr_1 from "../../Images/WebsiteImages/Virtual_Reality/1_simple_vr-min.webp";
import simple_vr_2 from "../../Images/WebsiteImages/Virtual_Reality/2_simple_vr-min.webp";
import simple_vr_3 from "../../Images/WebsiteImages/Virtual_Reality/3_simple_vr-min.webp";

const Virtualreality = () => {
  return (
    <>
      <HeroBanner img={VR_page_main_banner} />

      <div className="container p-10 text-white md:px-12 py-10">
        <h1 className="text-zinc-300 text-4xl md:text-3xl xl:text-4xl font-bold leading-tight md:leading-tight xl:leading-tight mb-8 md:mb-10 xl:mb-12">
          Virtual Reality won’t take away your traditional access.
        </h1>
        <div className="mt-8 mb-6 flex items-start space-x-3 font-Poppins leading-7 text-opacity-60 text-lg text-white font-normal  text-[18px] md:text-base" style={{
          textAlign:"justify"
        }}>
          VR technology has revolutionized various industries by providing
          immersive and interactive experiences. In education, VR enables
          realistic simulations and practical learning. In healthcare, it aids
          in training and surgery planning. In design and architecture, it
          facilitates virtual prototyping. VR also enhances tourism, gaming, and
          employee training, unlocking new possibilities and transforming
          industries.
        </div>
      </div>
      <ContentRightLeft
        title="Learning"
        imgSrc={simple_vr_1}
        desc="VR Learning Solutions revolutionize education by integrating virtual reality technology into the learning experience. With VR headsets and immersive environments, students can explore realistic simulations, engage in interactive activities, and gain practical knowledge in a safe and controlled setting. These solutions offer numerous benefits, such as enhancing engagement, retention, and understanding of complex concepts. By providing an interactive and hands-on approach, VR learning solutions foster active participation, critical thinking, and problem-solving skills. They enable students to visit historical landmarks, travel to distant planets, explore the human body, or even simulate scientific experiments—all within a virtual environment. VR Learning Solutions bridge the gap between theory and practice, allowing learners to grasp concepts in a more tangible and memorable way, ultimately transforming education into an exciting and immersive adventure."
      />

      <ContentRightLeft
        imgPosition="right"
        imgSrc={simple_vr_2}
        title="E-commerce"
        desc="VR Ecommerce Solutions bring the power of virtual reality to the world of online shopping. By leveraging VR technology, these solutions offer a highly immersive and interactive shopping experience to customers. Through VR headsets or compatible devices, shoppers can virtually browse through digital stores, visualize products in a lifelike 3D environment, and even try them out virtually. This immersive shopping experience enhances engagement, provides a sense of presence, and bridges the gap between physical and online retail. VR Ecommerce Solutions enable customers to explore products from every angle, examine details up close, and make informed purchase decisions. They also offer personalized recommendations, virtual showrooms, and interactive product demonstrations, creating a unique and engaging shopping journey. By merging the convenience of online shopping with the immersive nature of VR, these solutions redefine the way customers interact with products, fostering a more engaging and satisfying ecommerce experience."
      />
      <ContentRightLeft
        imgSrc={simple_vr_3}
        title="Games"
        desc="VR games offer an unparalleled level of immersion and interactivity, transporting players into virtual worlds and providing thrilling gaming experiences. By donning a VR headset, players can step into a fully immersive environment where they can interact with the virtual world using motion controllers or other input devices. These games often offer realistic graphics, 3D audio, and intuitive controls, allowing players to physically move, explore, and interact with their surroundings. VR games cover a wide range of genres, from action-packed adventures and intense shooters to immersive storytelling experiences and puzzle-solving challenges. They provide a sense of presence and a heightened level of excitement, as players feel like they are truly inside the game. Whether battling hordes of enemies, solving intricate puzzles, or exploring fantastical worlds, VR games offer a unique and captivating gaming experience that pushes the boundaries of traditional gaming and immerses players in new and exciting virtual realities."
      />
    </>
  );
};

export default Virtualreality;
