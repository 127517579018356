import React from "react";

const TestimonialsData = [
  {
    desc: `Super hit experience. I have been working with this company for more than a year. Very helpful and able to deliver quality work on time.`,
    name: "BC Producer",
    logo: "/assets/images/brands/logo1.png",
  },
  {
    desc: `The team's expertise in AR technology and their dedication to delivering a seamless and captivating experience was remarkable.`,
    name: "Vuteni",
    logo: "/assets/images/brands/logo3.png",
  },
  {
    desc: `The gaming work exceeded expectations with detailed graphics, smooth gameplay, and immersive storytelling. It's a favorite in my collection, providing endless enjoyment. Great job!`,
    name: "Abhishek",
    logo: "/assets/images/brands/logo4.png",
  },
  {
    desc: `Strong technical skill, and clarity of thought. Goes out of way to suggest the best approach. Good in finding solutions and RCA of complex issues. Always available round-the-clock with fast response times. Worthy of hiring for repeat work..`,
    name: "Gavriel",
    logo: "/assets/images/brands/logo6.png",
  },
  {
    desc: `The team's expertise in AR technology and their dedication to delivering a seamless and captivating experience was remarkable.`,
    name: "Hitarth",
    logo: "/assets/images/brands/logo5.png",
  },
];

export default function Testimonials() {
  return (
    // <section
    //   id="testimonials"
    //   className="px-10  pt-[20px] md:pt-[20px] lg:pt-[40px]"
    // >
    //   <div className="container">
    //     <div className="flex flex-wrap">
    //       <div className="w-full">
    //         <div className="mb-30px] mx-auto max-w-full text-center lg:mb-6">
    //           {/* <span className="mb-2 block text-lg font-medium text-white">
    //             How Brands.live is talked about?
    //           </span> */}
    //           <h2 className="mb-4 text-3xl font-bold text-white sm:text-4xl md:text-[42px]">
    //             Here's What Our Customers Have Said.
    //           </h2>
    //         </div>
    //       </div>
    //     </div>

    //     <div className="flex flex-wrap">
    //       <div className="w-full px-4 ">
    //         <TextSlider
    //           displayArrowPosition="side"
    //           isFullWidth={false}
    //           data={TestimonialsData}
    //           pagePage={3}
    //           DataType="Testimonials"
    //           autoplay={true}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <section id="testimonies" className="py-0 md:py-20">
      <div className="max-w-6xl mx-8 md:mx-10 lg:mx-20 xl:mx-auto">
        <div className="transition duration-500 ease-in-out transform scale-100 translate-x-0 translate-y-0 opacity-100">
          <div className="mb-12 space-y-5 md:mb-16 md:text-center">
            <div className="inline-block px-3 py-1 text-sm font-semibold text-indigo-100 rounded-lg md:text-center text-cn bg-[#202c47] bg-opacity-60 hover:cursor-pointer hover:bg-opacity-40">
              Words from Others
            </div>
            <h1 className="mb-5 text-3xl font-semibold text-white md:text-center md:text-5xl">
            Discover what our satisfied customers have to say!
            </h1>
            <p className="text-xl text-gray-100 md:text-center md:text-2xl">
              Here's what others have to say about us.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
          {TestimonialsData.map((item, index) => {
            return (
              <div className="text-sm leading-6" key={`testimonial_${index}`}>
                <div className="relative group">
                  <div className="absolute transition rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>

                  <div className="relative p-6 space-y-6 leading-none rounded-lg bg-slate-800 ring-1 ring-gray-900/5">
                    <div className="flex items-center space-x-4">
                      <img
                        src="https://pbs.twimg.com/profile_images/1276461929934942210/cqNhNk6v_400x400.jpg"
                        className="w-12 h-12 bg-center bg-cover border rounded-full"
                        alt="Kanye West"
                      />
                      <div>
                        <h3 className="text-lg font-semibold text-white">
                          {item.name}
                        </h3>
                      </div>
                    </div>
                    <p
                      className="font-poppins leading-7 text-opacity-60 text-[14px] text-white font-normal"
                      style={{
                        textAlign: "justify",
                      }}
                    >
                      {item.desc}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
