import React from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Components/Shared/layout";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Portfolio from "./Components/Pages/Portfolio";
import Gamedevelopment from "./Components/Pages/Gamedevelopment";
import Augmentedreality from "./Components/Pages/Augmentedreality";
import Modeling from "./Components/Pages/Modeling";
import Virtualreality from "./Components/Pages/Virtualreality";
import Contact from "./Components/Pages/Contact";
import Games from "./Components/Pages/Games";
import TagManager from "react-gtm-module";

function App({ Children }) {
  // gtm.js

  const gtmId = "	GTM-WS3769F8"; // Replace with your GTM ID

  const initializeTagManager = () => {
    TagManager.initialize({ gtmId });
  };

  // App.js

  useEffect(() => {
    initializeTagManager();
  }, []);

  return (
    <>
      {/* <script
        src="https://www.googletagmanager.com/gtag/js?id=G-CSC1B1D3R6"
        strategy="lazyOnload"
      /> */}
      {/* <script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag("js", new Date());

          gtag("config", "G-CSC1B1D3R6");
        `}
      </script>  */}

      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/game-development" element={<Gamedevelopment />} />
          <Route path="/augmented-reality" element={<Augmentedreality />} />
          <Route path="/3d-modeling" element={<Modeling />} />
          <Route path="/virtual-reality" element={<Virtualreality />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/games" element={<Games />} />
          <Route
            exact
            path="/normal_redirect"
            render={() => {
              window.location.href = "index.html";
            }}
          />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
