import React from "react";
import { useState, useEffect } from "react";
import whatsapplogo from "../../Images/WhatsApp_new.png";
// import playnow from "../../Images/PlayButton.png";
import logo_main from "../../Images/logo.png";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 100;

      const scrollY = window.scrollY || window.pageYOffset;

      setIsHeaderFixed(scrollY > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <div className="fixed bottom-4 right-4 z-50">
        <a
          href="https://api.whatsapp.com/message/QNF4RX5CUBGPF1"
          target="_blank"
          rel="noreferrer"
          className="pb-2"
        >
          <img
            src={whatsapplogo}
            alt="whatsapplogo"
            className="animate-bounce w-[60px] h-[60px]"
          />
        </a>

        {/* for games page button  */}

        {/* {pathname !== "/games" && (
          <a
            href="/games"
            title="play games"
            // className={`text-white rounded-full hidden  py-3 px-4 bg-yellow-500 md:block`}
          >
            <img
              src={playnow}
              className="animate-pulse hidden md:block w-[60px] h-[60px]"
            />
          </a>
        )} */}
      </div>
      <nav
        id="nav"
        className={`fixed inset-x-0 top-0 flex flex-row justify-between z-50    ${
          isHeaderFixed
            ? "bg-[#111827] text-white"
            : "bg-transparent text-white"
        } transition-all duration-500`}
      >
        <div className="p-4">
          <a
            href="/"
            className="font-extrabold tracking-widest text-xl text-center"
          >
            <img src={logo_main} className="headerlogo" alt="logo" />
          </a>
        </div>

        {/* <!-- Nav Items Working on Tablet & Bigger Screen --> */}
        <div className="p-4 hidden md:flex flex-row justify-between font-bold">
          <a
            id="hide-after-click"
            href="/"
            className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
          >
            Home
          </a>
          <a
            href="/about"
            className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
          >
            About Us
          </a>

          <div className="group relative cursor-pointer ">
            <div className="flex items-center">
              <span className="menu-hover mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500">
                Services
              </span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </div>

            <div className="invisible w-[200px] absolute z-50 flex flex-col bg-gray-100 py-5 px-4 text-gray-800 shadow-xl group-hover:visible">
              <a
                href="/game-development"
                className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
              >
                Game Development
              </a>

              <a
                href="/augmented-reality"
                className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
              >
                Augmented Reality
              </a>

              <a
                href="/virtual-reality"
                className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
              >
                Virtual Reality
              </a>

              <a
                href="/3d-modeling"
                className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
              >
                3D Modeling & Animation
              </a>
            </div>
          </div>
          <a
            href="/portfolio"
            className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
          >
            Portfolio
          </a>
          <a
            href="/contact"
            className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
          >
            Contact Us
          </a>
        </div>

        {/* <!-- Burger Nav Button on Mobile --> */}
        <div
          id="nav-open"
          className="p-4 md:hidden text-tution-20"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-menu text-gray-200"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
          {/* <div class="group flex h-20 w-20 cursor-pointer items-center justify-center rounded-3xl bg-white p-2 hover:bg-slate-200">
            <div class="space-y-2">
              <span class="block h-1 w-10 origin-center rounded-full bg-slate-500 transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45"></span>
              <span class="block h-1 w-8 origin-center rounded-full bg-orange-500 transition-transform ease-in-out group-hover:w-10 group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
            </div>
          </div> */}
        </div>
      </nav>

      {/* <!-- Opened Nav in Mobile --> */}

      {isMobileMenuOpen && (
        <div
          id="nav-opened"
          className="fixed md:hidden left-0 right-0  bg-[#111827] pt-14 rounded-br rounded-bl shadow z-10"
        >
          <div className="p-2 divide-y divide-gray-600 flex flex-col">
            <a href="/" className="p-2 font-semibold ">
              Home
            </a>
            <a href="/about" className="p-2 font-semibold ">
              About Us
            </a>
            <a href="/game-development" className="p-2 font-semibold ">
              Game Development
            </a>

            <a href="/augmented-reality" className="p-2 font-semibold ">
              Augmented Reality
            </a>

            <a href="/virtual-reality" className="p-2 font-semibold ">
              Virtual Reality
            </a>

            <a href="/3d-modeling" className="p-2 font-semibold ">
              3D Modeling & Animation
            </a>

            <a href="/portfolio" className="p-2 font-semibold ">
              Portfolio
            </a>
            <a href="/contact" className="p-2 font-semibold ">
              Contact Us
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
