import React, { useState, useEffect } from "react";

// import YoutubeVideos from "../Shared/YoutubeVideos";

const Games = () => {
  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://zaapsolutions.com/WebGames/GameStore/gamestorlist.json"
        );
        const data = await response.json();
        setGameData(data.data); // Set the fetched data to state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data when the component mounts
  }, []);

  const filterData = gameData.filter((item) => item.title === "Games");
  console.log("Filtered data:", filterData);
  return (
    <>
      <div className="container my-10 lg:mb-4  text-center pt-10">
        <h1 className="mb-0 text-[40px] font-bold font-['Poppins'] leading-snug text-white sm:text-2xl sm:leading-snug md:text-[36px] md:leading-snug">
          Explore Now
        </h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 p-4 my-5">
        {filterData?.map((item) =>
          item.games.map((game, key) => {
           
            return (
              <a
                key={`ITEM_${game.id}${key}`}
                href={game.game_url}
                target="_blank"
                rel="noreferrer"
                className="group cursor-pointer relative"
              >
                <div className="relative">
                  <div className="absolute inset-0 transition rounded-lg opacity-25 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>
                  <img
                    src={game.thumb_url}
                    alt={game.name}
                    className="w-full h-[300px] object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-100"
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-12 w-12 text-white bg-purple-600 rounded-full p-3 hover:bg-purple-700 transition duration-300"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 5a1 1 0 011.597-.802l6 4a1 1 0 010 1.604l-6 4A1 1 0 017.5 15V5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </a>
            );
          })
        )}
      </div>
    </>
  );
};

export default Games;
