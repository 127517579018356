import React from "react";
import { useState, useRef } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReCAPTCHA from "react-google-recaptcha";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  console.log("formData.name=>", formData.name);
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef(null);
  const data = (
    <div>
      Thank you for reaching out!
      <br />
      <span style={{ display: "block" }}>
        Your message has been received. We'll respond shortly.
      </span>
    </div>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormData({
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });

    const { name, email, subject, message, phone } = formData;

    if (isRecaptchaVerified) {
      try {
        // Your API endpoint URL
        const url =
          "https://zaapsolutions.com/WebGames/mailservices/contact.php";
        const formDataToSend = new FormData();

        // Append form fields to FormData object
        formDataToSend.append("name", name);
        formDataToSend.append("email", email);
        formDataToSend.append("phone", phone);
        formDataToSend.append("subject", subject);
        formDataToSend.append("message", message);

        // Fetch API call to send form data
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          body: formDataToSend,
        });

        if (response.ok) {
          // Reset the form data and ReCAPTCHA verification status
          toast(data);
          setFormData({
            name: "",
            phone: "",
            email: "",
            subject: "",
            message: "",
          });
          setRecaptchaVerified(false);
          recaptchaRef.current.reset();
        } else {
          console.error("Failed to submit form data");
          // Handle error scenarios
        }
      } catch (error) {
        console.error("Error submitting form data:", error);
        // Handle error scenarios
      }
    } else {
      alert("Please complete the ReCAPTCHA verification.");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChange = (value) => {
    setRecaptchaVerified(true);
  };

  return (
    <>
      <div className="min-h-screen my-10 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-700 to-purple-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="text-white relative px-4 py-10 bg-indigo-400 shadow-lg sm:rounded-3xl sm:p-20">
            <div className="text-center pb-6">
              <h1 className="text-3xl">Contact Us!</h1>

              <p className="text-gray-300">
                Fill up the form below to send us a message.
              </p>
            </div>

            <form onSubmit={handleSubmit}>
              <input
                className="shadow mb-4 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Name"
                value={formData.name}
                onChange={(e) => handleInputChange(e)}
                name="name"
                required
              />
              <input
                className="shadow mb-4 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                placeholder="Mobile Number"
                value={formData.phone}
                onChange={(e) => handleInputChange(e)}
                name="phone"
                style={{
                  WebkitAppearance: "none", // Hide spinner in Chrome and Safari
                  // Ensure no extra space is added
                }}
                required
              />
              <input
                className="shadow mb-4 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => handleInputChange(e)}
                name="email"
                required
              />
              <input
                className="shadow mb-4 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Subject"
                value={formData.subject}
                onChange={(e) => handleInputChange(e)}
                name="subject"
              />
              <textarea
                className="shadow mb-4 min-h-0 appearance-none border rounded h-64 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Type your message here..."
                name="message"
                value={formData.message}
                onChange={(e) => handleInputChange(e)}
                style={{ height: "121px" }}
              ></textarea>

              <ReCAPTCHA
                ref={recaptchaRef}
                render="explicit"
                sitekey="6LcI6VQpAAAAAECYhnKgE1c4gIBWhS0qKXwmZ1dg"
                // sitekey="6LfWFJQpAAAAAA9AN8DdGkCtui9xjdve2DRqCfGl"
                // sitekey="6LdEuX0pAAAAAK5batJ2-677mSnm8xSsued77OpA"
                // sitekey="6LesMlUpAAAAAD7GnqD4gTOkJB8_itzdOlokdGyl" //localhostkey
                onChange={(e) => handleChange(e)}
              />
              <div className="flex justify-between pt-4">
                <input
                  className={`shadow bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                    !isRecaptchaVerified && "cursor-not-allowed opacity-50"
                  }`}
                  type="submit"
                  value="Send ➤"
                  disabled={!isRecaptchaVerified}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition="zoom"
      />
    </>
  );
};

export default Contact;
