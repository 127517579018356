import React from "react";
import HeroBanner from "../Shared/HeroBanner";
import ContentRightLeft from "../Shared/ContentRightLeft";

import main_banner_AR from "../../Images/WebsiteImages/Augmented_Reality/AR.webp";
import simple_ar_1 from "../../Images/WebsiteImages/Augmented_Reality/1_simple_ar.webp";
import simple_filters_4 from "../../Images/WebsiteImages/Augmented_Reality/4_simple_filters.webp";
import marked_base from "../../Images/WebsiteImages/Augmented_Reality/2_simple_ar_Marker-based-tracking.webp";
import marked_less from "../../Images/WebsiteImages/Augmented_Reality/3_simple_surface.webp";

const Augmentedreality = () => {
  return (
    <>
      <HeroBanner img={main_banner_AR} />
      <div className=" text-center text-white my-10 p-10">
        <h1 className="text-zinc-300 text-[40px] font-bold leading-tight md:leading-tight xl:leading-tight mb-8 md:mb-10 xl:mb-12">
          We Develop Strategic Software Solutions For Businesses
        </h1>
        <div className="mt-8 mb-6 flex items-start space-x-3   font-['Poppins'] leading-7 text-opacity-60 text-[16px] text-white font-normal">
          {` AR revolutionizes industries: retail with virtual try-on, real estate
          with virtual tours, healthcare with training and surgery assistance,
          manufacturing with remote collaboration, education with interactive
          learning, tourism with augmented guides, architecture with 3D
          visualization, entertainment with immersive gaming, and marketing with
          engaging campaigns. AR enhances experiences and boosts efficiency.`}
        </div>
      </div>

      <ContentRightLeft
        title="Marker Based AR"
        imgSrc={marked_base}
        desc="Marker-Based Augmented Reality relies on markers, which are predefined visual cues, to trigger digital content in the real world. These markers act as portals, enhancing your surroundings with a layer of virtual information, animations, or 3D models when viewed through a compatible device's camera. The interactive AR content can include product showcases, educational demonstrations, or entertaining visual elements that captivate and inform users."
      />
      <ContentRightLeft
        title="Markerless AR"
        imgPosition="right"
        imgSrc={marked_less}
        desc="Markerless Augmented Reality (AR) refers to the use of augmented reality technology without the need for physical markers or predefined patterns. In traditional AR setups, physical markers (such as QR codes or specific images) act as triggers for the AR system to overlay digital content onto the real world. Markerless AR, on the other hand, relies on advanced computer vision, sensors, and algorithms to recognize and track real-world objects and surfaces, enabling the seamless integration of virtual elements into the environment."
      />
      <ContentRightLeft
        title="AR Game"
        imgSrc={simple_ar_1}
        desc="AR games, or Augmented Reality games, blend virtual elements with the real world, creating captivating and interactive experiences. These games leverage the camera and sensors of mobile devices to overlay digital content onto the user's surroundings, enhancing the real world with virtual objects and characters. Players can see and interact with these virtual elements through their device's screen. AR games encourage players to explore their physical environment while engaging with virtual challenges, puzzles, and characters. They provide a unique and immersive gaming experience that seamlessly integrates the virtual and real worlds. Players can interact with virtual objects in their living room, hunt for virtual creatures in the park, or solve mysteries in their own neighborhood. AR games promote physical activity, creativity, and social interaction, as players can collaborate or compete with friends and other players in real-time AR experiences. With their ability to transform the everyday environment into a magical and interactive playground, AR games offer an exciting and innovative way to play and engage with the world around us."
      />
      <ContentRightLeft
        imgPosition="right"
        imgSrc={simple_filters_4}
        title="AR Filters"
        desc="AR filters, also known as augmented reality filters or lenses, are interactive visual overlays that can be applied to photos or videos in real-time using AR technology. These filters are popularly used on social media platforms and messaging apps, allowing users to add fun, creative, and sometimes informative effects to their selfies or camera footage. AR filters can range from simple enhancements like adding virtual makeup or changing backgrounds to more elaborate transformations such as turning users into animals, adding animated objects, or applying artistic filters. These filters utilize facial tracking and object recognition algorithms to accurately map and align the virtual elements with the user's face or the surrounding environment. AR filters provide an engaging and entertaining way for users to express themselves, share moments, and enhance their visual content. They have become a significant part of social media culture, encouraging users to interact, experiment, and share their experiences with augmented reality in a playful and imaginative manner."
      />
    </>
  );
};

export default Augmentedreality;
