import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import YoutubeVideos from "../Shared/YoutubeVideos";
import portfolio_main_banner from "../../Images/WebsiteImages/Portfolio/portfolio_main_banner_2-min.webp";

import HeroBanner from "../Shared/HeroBanner";
const Portfolio = () => {
  const navigate = useNavigate();                                                           

  const [resData, setResData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://zaapsolutions.com/WebGames/GameStore/gamestorlist.json"
        );
        const data = await response.json();
  
        setResData(data.data);
        setSelectedData(data.data[0]); // Set the fetched data to state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data when the component mounts
  }, []);
  const handleChipClick = (index, item) => {
    index === 1 && navigate("/games");
    setActiveIndex(index);
    setSelectedData(item);
   
  };

  
  return (
    <>
      <HeroBanner img={portfolio_main_banner} />
      <div className="container my-10 lg:mb-4  text-center">
        <h1 className="mb-0 text-[40px] font-bold font-['Poppins'] leading-snug text-white sm:text-2xl sm:leading-snug md:text-[36px] md:leading-snug">
          Few of Our Apps Adored by millions of users!
        </h1>
      </div>
      <div className="flex justify-center flex-wrap my-5">
        {resData.map((item, index) => (
          <div
            key={`portfolio_${index}`}
            className="relative inline-flex group mr-5 mb-5" // Add margin bottom for spacing
          >
            <div className="absolute transition rounded-full opacity-25 -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>

            <a
              onClick={() => handleChipClick(index, item)}
              className={`relative inline-flex items-center justify-center px-8 py-4 text-lg font-semibold text-white transition-all duration-200 bg-gray-800 font-pj rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ${
                activeIndex === index
                  ? "outline-none ring-2 ring-offset-2 ring-gray-900"
                  : ""
              }`}
              role="button"
              style={{ minWidth: "150px" }} // Adjust as needed
            >
              {item.title}
            </a>
          </div>
        ))}
      </div>

      {activeIndex === 0 && (
        <YoutubeVideos ImageData={selectedData?.video_url} viewType="videos" />
      )}
      {activeIndex === 2 && (
        <YoutubeVideos ImageData={selectedData?.vr} viewType="metaverse" />
      )}
      {activeIndex === 3 && (
        <YoutubeVideos ImageData={selectedData?.ar} viewType="metaverse" />
      )}
      {activeIndex === 4 && (
        <YoutubeVideos ImageData={selectedData?.filters} viewType="arfilter" />
      )}
    </>
  );
};

export default Portfolio;
