import React from "react";
// import BgGradiant from "./BgGradiant";

const ContentRightLeft = ({
  textColor = "white",
  imgSrc = "https://images.unsplash.com/photo-1614624532983-4ce03382d63d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1931&q=80",

  imgPosition = "left",
  title,
  desc,
  button1,
  button2,
}) => {
  return (
    <>
      <div
        className={`container mx-auto text-white  mt-10 ${
          imgPosition === "left" ? "flex" : "flex flex-row-reverse"
        } flex-wrap lg:flex-nowrap lg:gap-20 lg:items-center  p-10 `}
      >
        {imgSrc && (
          <div
            className={`flex w-full items-center ${
              imgPosition === "right" ? "justify-end" : ""
            }   lg:w-1/2`}
            style={{
              borderRadius: "20px",
            }}
          >
            <img
              alt="Benefits"
              src={imgSrc}
              decoding="async"
              data-nimg="true"
              // height="300"
              // width="300"
              className="h-auto w-auto max-w-full rounded-[20px]"
            />
          </div>
        )}

        <div className="flex w-full flex-wrap lg:w-1/2">
          <div className="w-full">
            <div className="mt-4 flex w-full flex-col">
              <h2
                className={`mt-3 max-w-2xl text-3xl lg:text-4xl text-[40px] font-bold leading-snug tracking-tight ${
                  textColor ? textColor : "text-gray-800"
                } font-poppins lg:leading-tight`}
              >
                {title}
              </h2>
            </div>
            <div className="mt-5 ">
              <div
                className="mt-8 mb-6 flex items-start space-x-3 font-poppins leading-7 text-opacity-60 text-[18px] text-white font-normal"
                style={{
                  textAlign: "justify",
                }}
              >
                {desc}
              </div>
              <ul className="flex flex-wrap">
                {button1 && (
                  <li>
                    <button className="inline-flex items-center justify-center rounded-lg bg-blue-500 px-6 py-4 text-center text-base font-semibold tracking-widest text-white transition duration-300 ease-in-out font-poppins hover:shadow-lg sm:px-6 md:w-[196px]">
                      {button1}
                    </button>
                  </li>
                )}
                {button2 && (
                  <li>
                    <button className="ml-4 flex items-center rounded-lg bg-white px-6 py-4 text-base font-semibold tracking-wide text-primary transition duration-300 ease-in-out hover:opacity-70 font-poppins sm:px-6">
                      {button2}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentRightLeft;
