import React from "react";
import HeroBanner from "../Shared/HeroBanner";

import Main_banner_modeling from "../../Images/WebsiteImages/GameDevelopment/game-development_page_main_banner.webp";
import game_development_simple from "../../Images/WebsiteImages/GameDevelopment/2d_game_simple.webp";
import game_development_3d from "../../Images/WebsiteImages/GameDevelopment/3d_game_simple.webp";

import ContentRightLeft from "../Shared/ContentRightLeft";

const Gamedevelopment = () => {
  return (
    <>
      <HeroBanner img={Main_banner_modeling}  />
      <ContentRightLeft
        title="Game Development"
        imgSrc={game_development_simple}
        desc="The gaming industry is booming, offering diverse and immersive experiences. Cutting-edge technology like virtual reality (VR) and augmented reality (AR) has transformed gaming, providing unprecedented levels of immersion and interactivity. With stunning graphics, innovative gameplay mechanics, and engaging storytelling, gaming continues to captivate players worldwide, shaping the future of entertainment."
      />
      <ContentRightLeft
        imgPosition="right"
        imgSrc={game_development_3d}
        title="We serve the best work"
        desc="3D gaming has revolutionized the gaming industry, immersing players in rich and realistic virtual worlds. With stunning graphics, lifelike animations, and dynamic environments, 3D games provide a heightened level of immersion and interactivity. Whether exploring vast open worlds or engaging in intense battles, 3D gaming offers thrilling and captivating experiences for gamers worldwide."
      />
    </>
  );
};

export default Gamedevelopment;
