import React from "react";

const YoutubeVideos = ({ ImageData, viewType = "" }) => {
  // const ImageData = [
  //   {
  //     id: 1,
  //     img: "https://www.youtube.com/embed/9KeMNQysoTE",
  //   },
  //   {
  //     id: 2,
  //     img: "https://www.youtube.com/embed/MpeAWGyjgy4",
  //   },
  //   {
  //     id: 3,
  //     img: "https://www.youtube.com/embed/zKvpUGebJQQ",
  //   },
  //   {
  //     id: 4,
  //     img: "https://www.youtube.com/embed/gyiEIKQz_ns",
  //   },
  //   {
  //     id: 5,
  //     img: "https://www.youtube.com/embed/phkSneUORmU",
  //   },
  //   {
  //     id: 6,
  //     img: "https://www.youtube.com/embed/k1FKO6NgA4U",
  //   },
  //   {
  //     id: 7,
  //     img: "https://www.youtube.com/embed/vqLyxfxN5rA",
  //   },
  // ];
  const extractVideoId = (url) => {
    const parts = url.split("/");

    const videoId = parts[parts.length - 1];

    return videoId;
  };
  return (
    <>
      {viewType !== "arfilter" ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 p-4 my-5">
          {viewType === "videos" &&
            ImageData?.map((item, key) => {
              return (
                <div
                  key={`ITEM_${item}${key}`}
                  className="group cursor-pointer relative"
                >
                  <div className="absolute transition rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>
                  <iframe
                    src={`https://www.youtube.com/embed/${extractVideoId(
                      item
                    )}`}
                    alt="Image 1"
                    title={key}
                    className="w-full h-[300px] object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-100"
                  />
                </div>
              );
            })}

          {viewType === "metaverse" &&
            ImageData?.map((item, key) => {
              return (
                <div
                  key={`ITEM_${item}${key}`}
                  className="group cursor-pointer relative"
                >
                  <div className="absolute transition rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>

                  {item.type === "video" ? (
                    <iframe
                      src={`https://www.youtube.com/embed/${extractVideoId(
                        item.url
                      )}`}
                      alt="Image 1"
                      title={key}
                      className="w-full h-[300px] object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-100"
                    />
                  ) : (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className="group cursor-pointer relative"
                    >
                      <div className="relative">
                        <div className="absolute inset-0 transition rounded-lg opacity-25 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>
                        <img
                          src={item.thumb_url}
                          alt="play"
                          className="w-full h-[300px] object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-100"
                        />
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12 text-white bg-purple-600 rounded-full p-3 hover:bg-purple-700 transition duration-300"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.5 5a1 1 0 011.597-.802l6 4a1 1 0 010 1.604l-6 4A1 1 0 017.5 15V5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-6 p-4 my-5 ">
          {ImageData?.map((item, key) => {
            return (
              <div
                key={`ITEM_${item}${key}`}
                className="group cursor-pointer relative flex justify-center items-center"
              >
                {item.type === "video" ? (
                  <iframe
                    src={`https://www.youtube.com/embed/${extractVideoId(
                      item.url
                    )}`}
                    alt="Image 1"
                    title={key}
                    className="w-full h-[300px] object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-100"
                  />
                ) : (
                  <img
                    src={item.url}
                    alt="images"
                    className="h-[250px] rounded-lg transition-transform transform scale-100 group-hover:scale-100"
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default YoutubeVideos;
