import React from "react";

import Counter from "./Counter";

const Odometer1 = () => {
  return (
    <>
      <div className="text-center">
        <h1 className="text-zinc-300 text-[40px] font-bold leading-tight md:leading-tight xl:leading-tight mb-8 md:mb-10 xl:mb-12">
          We Develop Strategic Software Solutions For
          <br /> Businesses
        </h1>

        <div
          className="flex flex-wrap justify-center text-center text-white p-5"
          id="counter"
        >
          <div className="w-full md:w-1/2 lg:w-1/4 p-5 border-b md:border-b-0 md:border-r-2">
            <span className="stat-count text-white text-5xl md:text-6xl xl:text-7xl font-medium leading-tight md:leading-tight xl:leading-tight">
              <Counter value={2} />
              <Counter value={0} />
              <Counter value={1} />
              <Counter value={9} />
            </span>
            <div className="text-zinc-300 text-sm md:text-base xl:text-lg font-normal mt-2 md:mt-4 xl:mt-6">
              Year of establishment
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 p-5 border-b md:border-b-0 md:border-r-2">
            <span className="stat-count text-white text-5xl md:text-6xl xl:text-7xl font-medium leading-tight md:leading-tight xl:leading-tight">
              <Counter value={2} />
              <Counter value={5} />
              <Counter value={0} />+
            </span>
            <div className="text-zinc-300 text-sm md:text-base xl:text-lg font-normal mt-2 md:mt-4 xl:mt-6">
              Clients
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 p-5 border-b md:border-b-0 md:border-r-2">
            <span className="stat-count text-white text-5xl md:text-6xl xl:text-7xl font-medium leading-tight md:leading-tight xl:leading-tight">
              <Counter value={25} /> +
            </span>
            <div className="text-zinc-300 text-sm md:text-base xl:text-lg font-normal mt-2 md:mt-4 xl:mt-6">
              Countries with active client base
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 p-5">
            <span className="stat-count text-white text-5xl md:text-6xl xl:text-7xl font-medium leading-tight md:leading-tight xl:leading-tight">
              <Counter value={5} />
            </span>
            <div className="text-zinc-300 text-sm md:text-base xl:text-lg font-normal mt-2 md:mt-4 xl:mt-6">
              Customer rating
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Odometer1;
