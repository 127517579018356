import React from "react";

import HeroBanner from "../Shared/HeroBanner";
import ContentRightLeft from "../Shared/ContentRightLeft";
import aboutus_page_main_banner from "../../Images/WebsiteImages/About_Us/aboutus_page_main_banner.webp";
import aboutus_simple_1 from "../../Images/WebsiteImages/About_Us/1_aboutus_simple.webp";
import aboutus_simple_2 from "../../Images/WebsiteImages/About_Us/2_aboutus_simple.webp";
import aboutus_simple_3 from "../../Images/WebsiteImages/About_Us/3_aboutus_simple.webp";

const About = () => {
  return (
    <>
      <section className="">
        <HeroBanner img={aboutus_page_main_banner} />

        <ContentRightLeft
          // title="About Our Company"
          imgSrc={aboutus_simple_1}
          desc="At Zaap Solutions, we are passionate about the world of gaming and the endless possibilities that AR and VR technologies bring. We are dedicated to creating unforgettable gaming adventures and pushing the boundaries of reality through our innovative products and services.With 5 years of expertise in the gaming and AR/VR industry, we have established ourselves as a leading force in this rapidly evolving field. Our team of skilled developers, designers, and technologists are at the forefront of innovation, constantly exploring new ways to enhance your gaming experiences and blur the lines between the virtual and physical worlds."
        />
        <ContentRightLeft
          imgPosition="right"
          imgSrc={aboutus_simple_2}
          // title="About Our Company"
          desc="We offer a wide range of gaming products and services to cater to gamers of all ages and preferences. From captivating virtual reality games that transport you to new dimensions, to interactive augmented reality experiences that seamlessly blend digital content with your surroundings, we strive to deliver immersive and mind-blowing adventures.Our commitment to quality and customer satisfaction is unwavering. We meticulously craft every detail of our games and experiences to ensure they are engaging, realistic, and awe-inspiring. We understand that gaming is not just a pastime but a form of escapism and self-expression, and we aim to create experiences that leave a lasting impact.."
        />
        <ContentRightLeft
          imgSrc={aboutus_simple_3}
          // title="About Our Company"
          desc="In addition to our gaming offerings, we also provide AR/VR solutions for various industries. Whether it's architecture and design, education and training, or marketing and advertising, our AR/VR applications can revolutionize the way businesses operate, communicate, and engage with their audiences.At Zaap Solutions, we believe in fostering a sense of community and bringing people together through gaming and AR/VR experiences. We organize events, tournaments, and social gatherings that allow gamers and enthusiasts to connect, share their passion, and create lasting memories."
        />
      </section>
    </>
  );
};

export default About;
