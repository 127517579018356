import React from "react";
import Rectangle69 from "../../Images/WebsiteImages/HomePage/3d-modeling-min.webp";
import Rectangle71 from "../../Images/WebsiteImages/HomePage/AR-filter-min.webp";
import Rectangle73 from "../../Images/WebsiteImages/HomePage/AR-min.webp";
import Rectangle74 from "../../Images/WebsiteImages/HomePage/VR-min.webp";
import Rectangle75 from "../../Images/WebsiteImages/HomePage/game-development -min.webp";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageData = [
  {
    img: Rectangle69,
    name: "3D Modelling",
    desc: `3D modeling is the process of creating digital representations of objects or environments in three dimensions. It involves using specialized software to sculpt, manipulate, and texture virtual objects, bringing them to life. 3D modeling finds applications in various industries, including gaming, animation, architecture, and product design.`,
    slug: "/daily/devotional",
  },
  {
    img: Rectangle71,
    name: "AR Filter",
    desc: `AR filters, also known as augmented reality filters or lenses, are interactive visual overlays that can be applied to photos or videos in real-time using AR technology. These filters are popularly used on social media platforms and messaging apps, allowing users to add fun, creative, and sometimes informative effects to their selfies or camera footage. `,
    slug: "/daily/motivation",
  },

  {
    img: Rectangle73,
    name: "Augmented Reality",
    desc: `AR revolutionizes industries: retail with virtual try-on, real estate with virtual tours, healthcare with training and surgery assistance, manufacturing with remote collaboration, education with interactive learning, tourism with augmented guides, architecture with 3D visualization, entertainment with immersive gaming, and marketing with engaging campaigns. AR enhances experiences and boosts efficiency.`,
    slug: "/daily/memes",
  },
  {
    img: Rectangle74,
    name: "Virtual Reality",
    desc: `VR technology has revolutionized various industries by providing immersive and interactive experiences. In education, VR enables realistic simulations and practical learning. In healthcare, it aids in training and surgery planning. In design and architecture, it facilitates virtual prototyping. VR also enhances tourism, gaming, and employee training, unlocking new possibilities and transforming industries.`,
    slug: "/daily/memes",
  },
  {
    img: Rectangle75,
    name: "Game development",
    desc: `The gaming industry is booming, offering diverse and immersive experiences. Cutting-edge technology like virtual reality (VR) and augmented reality (AR) has transformed gaming, providing unprecedented levels of immersion and interactivity. With stunning graphics, innovative gameplay mechanics, and engaging storytelling, gaming continues to captivate players worldwide, shaping the future of entertainment.`,
    slug: "/daily/memes",
  },
];

const ServiceProvide = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <section className="pb-[60px] pt-[50px]  ">
        <div className="container mb-2 lg:mb-4 mx-auto text-center">
          <h1 className="mb-0 text-[40px] font-bold font-['Poppins'] leading-snug text-white sm:text-2xl sm:leading-snug md:text-[36px] md:leading-snug">
            Choose The Best IT
            <br />
            Service Company
          </h1>

          <div className="w-full max-w-7xl mx-auto mt-10">
            <Slider {...settings} arrows={false}>
              {ImageData.map((item, index) => (
                <div key={`slider_${item.name}`} className="px-4 relative cursor-pointer ">
                  <div className="rounded-lg overflow-hidden relative">
                    <img
                      src={item.img}
                      alt={item.name}
                      className="w-full h-auto"
                    />
                    <div className="hover-content absolute bottom-0 left-0 w-full bg-black bg-opacity-80  py-3">
                      <div className="text-white text-center">
                        <div className="text-white text-base font-medium mb-1 font-['Poppins']">
                          {item.name}
                        </div>
                        <div className="top-[32px] line-clamp-3  text-white text-opacity-60 text-sm font-normal font-['Poppins']">
                          {item.desc}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceProvide;
