import React from "react";
import HeroBanner from "../Shared/HeroBanner";
import ContentRightLeft from "../Shared/ContentRightLeft";
import ServiceProvide from "../Shared/ServiceProvide";
import Testimonials from "../Shared/Testimonials";
import OdoMeater from "../Shared/OdoMeater";
import img from "../../Images/WebsiteImages/HomePage/HomePageBanner1.webp";
import img2 from "../../Images/WebsiteImages/HomePage/HomePageBanner2.webp";
import img3 from "../../Images/WebsiteImages/HomePage/HomePageBanner3.webp";
import img4 from "../../Images/WebsiteImages/HomePage/HomePageBanner4.webp";
import about_us_home_page from "../../Images/WebsiteImages/HomePage/AboutUS.webp";

const Home = () => {
  return (
    <>
      <HeroBanner
        img={img}
        img2={img2}
        img3={img3}
        img4={img4}
        viewType="home"
      />

      <ContentRightLeft
        title="About Our Company"
        imgSrc={about_us_home_page}
        desc="At Zaap Solutions, we are passionate about the world of gaming and the endless possibilities that AR and VR technologies bring. We are dedicated to creating unforgettable gaming adventures and pushing the boundaries of reality through our innovative products and services.With 5 years of expertise in the gaming and AR/VR industry, we have established ourselves as a leading force in this rapidly evolving field. Our team of skilled developers, designers, and technologists are at the forefront of innovation, constantly exploring new ways to enhance your gaming experiences and blur the lines between the virtual and physical worlds."
      />
      <OdoMeater />
      <ServiceProvide />

      {/* <div class="h-screen w-screen bg-black ">
        <div class="relative pt-20">
          <div aria-hidden="true" class="hidden sm:block">
            <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-800 rounded-r-3xl"></div>
            <svg
              class="absolute top-8 left-1/2 -ml-3"
              width="404"
              height="392"
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="392"
                fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
              ></rect>
            </svg>
          </div>
          <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div class="relative rounded-2xl px-6 py-10 bg-gray-700 overflow-hidden shadow-xl sm:px-12 sm:py-20">
              <div
                aria-hidden="true"
                class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
              >
                <svg
                  class="absolute inset-0 h-full w-full"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 1463 360"
                >
                  <path
                    class="text-gray-600 text-opacity-40"
                    fill="currentColor"
                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                  ></path>
                  <path
                    class="text-gray-800 text-opacity-40"
                    fill="currentColor"
                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                  ></path>
                </svg>
              </div>
              <div class="relative flex flex-col">
                <div class="sm:text-center">
                  <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                    {" "}
                    Push hot code updates
                  </h2>
                  <p class="mt-6 mx-auto max-w-2xl text-lg text-gray-100">
                    {" "}
                    Like you push a commit{" "}
                  </p>
                </div>
                <a
                  href="#"
                  class="w-auto inline-block mt-5 mx-auto rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
                >
                  Get started →
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Testimonials />
    </>
  );
};

export default Home;
